import { all, takeEvery, put, call } from 'redux-saga/effects';
import { fetchSampleCount, downloadSampleCount } from 'services/sampleCount';
import { notification } from 'antd';
import actions from './actions';

export function* callFetchSampleCount({ payload }) {
  try {
    const data = yield call(fetchSampleCount, payload);

    yield put({
      type: actions.FETCH_SAMPLE_COUNT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    notification.error(error);

    yield put({
      type: actions.FETCH_SAMPLE_COUNT_FAILURE,
      payload: {
        error: error?.message,
      },
    });
  }
}

export function* callDownloadSampleCount({ payload }) {
  try {
    yield call(downloadSampleCount, payload);

    yield put({
      type: actions.DOWNLOAD_SAMPLE_COUNT_SUCCESS,
    });
  } catch (error) {
    notification.error({ message: error?.message });

    yield put({
      type: actions.DOWNLOAD_SAMPLE_COUNT_FAILURE,
      payload: {
        error: error?.message,
      },
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_SAMPLE_COUNT_REQUEST, callFetchSampleCount),
    takeEvery(actions.DOWNLOAD_SAMPLE_COUNT_REQUEST, callDownloadSampleCount),
  ]);
}
