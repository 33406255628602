import actions from './actions';

const initialState = {
  data: {},
  error: null,
  isLoading: false,
  isDownloading: false,
};

export default function sampleCountReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_SAMPLE_COUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case actions.FETCH_SAMPLE_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actions.FETCH_SAMPLE_COUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case actions.DOWNLOAD_SAMPLE_COUNT_REQUEST:
      return {
        ...state,
        isDownloading: true,
      };
    case actions.DOWNLOAD_SAMPLE_COUNT_SUCCESS:
      return {
        ...state,
        isDownloading: false,
      };
    case actions.DOWNLOAD_SAMPLE_COUNT_FAILURE:
      return {
        ...state,
        isDownloading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
